import React, { } from 'react'



function TestApp(props:PropsValue) {





  return(
    <div id="test">
      TQ TABLET
    </div>
     
  )
}

export default TestApp

