import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import config from 'react-global-configuration';
import configuration from './config.js';
import MovieApp from "./components/movie/MovieApp.js"
import FavoriteListApp from './components/favorites/FavoriteListApp.js';
import TestApp from './components/TestApp.js';

config.set(configuration);

class App extends React.Component{

render(){
return(
    <div className="main">
      <Routes>
        <Route  path='/' element={<TestApp />} />
        <Route  path='/:movie_id' element={<MovieApp />} />
        <Route  path='/favorite' element={<FavoriteListApp />} />
      </Routes> 
    </div>
)}}

const container = document.getElementById('root')

if(container){
  const root = createRoot(container)
  root.render((<BrowserRouter ><App /></BrowserRouter>))
}
