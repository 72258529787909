import React, { useState , useEffect} from 'react'
import { Thumnail } from '../../stories/Thumnail';
import useFavoriteState from './useFavoriteState';
import { Header } from '../../stories/common/Header';


function FavoriteListApp(props:PropsValue) {

  const [list, setList ] = useState([])
  const {favorites, getFavorites} = useFavoriteState()


  useEffect(() => {
    getFavorites()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(() => {

    const _list = favorites.reverse().map(item => {
       return <Thumnail movie_id={item} />
    })

    if(_list.length > 0 ){
      setList(_list)
    }else{
      setList(<p>現在お気に入りに登録されている動画はありません。</p>)
    }
   
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[favorites])

  

  return(
    <div id="movie-list">
      <Header />
       <section className="movies"> 
        <div className="container">
          {list}
        </div>
      </section>
    </div>
     
  )
}

export default FavoriteListApp

