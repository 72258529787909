import { useState } from 'react';

import MovieAction from './MovieAction';
import Movie from './Movie';

function useMovieState() {

  const [item, setItem] = useState(null)

  const loadedHandler = (data) => {
    if(data)
      setItem(new Movie(data.video_id, data.title, data.thumbnail_url))
  }

  const get = (id) => {
    MovieAction.get(id, loadedHandler)
  } 


  return { movie_info:item,  getMovieInfo:get};
}

export default useMovieState