
import 'whatwg-fetch'

const Action = {


  fetchJson( path, method, header, body=null,  callbackHandler = null, errorHandler = null){

    fetch(path  , {
      method: method,
      headers: header,
      body: body ? body : null
    }).then(function(response) {
      
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":response.status}
        }
      }else if(response.status === 401){
        return {"error":401}
      }else if(response.status === 403){
        return  {"error":413}
      }else{
        throw new Error('Network response was not ok.');
      }
    }).then(function(data) {
      if(data && !data.error && callbackHandler){
        callbackHandler(data)
      }else{
        if(data && data.error === 403){
          Error.authError()
        }
        if(errorHandler){
          errorHandler(data)
        }
      }
    })
  },

 
}

export default Action
