import React from 'react';
import './header.css';
import header_img from "./img/title_bar.png"
interface HeaderProps {
 
}

/**
 * Primary UI component for user interaction
 */
export const Header = ({
  ...props
}: HeaderProps) => {
  
  return (
    <header>
      <div className="header-wrap">
       <img src={header_img} alt="TQタブレット" />
      </div>
    </header>
  );
};
