
import 'whatwg-fetch'

import cookie from 'react-cookies'

const cookie_name = "favorites" 
const FavoriteAction = {  



  load(callback){
    const list =  cookie.load(cookie_name)
    callback(list)
  },

  add(id, callback){
    var list =  cookie.load(cookie_name)
    if(list){
      list.push(id)
    } else {
      list = [id]
    }
    cookie.save(cookie_name, list, { path: '/' })
    callback(list)
  },

  remove(id, callback){
    var list =  cookie.load(cookie_name)
    if(list){
      const index = list.findIndex(item => item === id) 
      console.log(index)
      list.splice(index, 1)
      cookie.save(cookie_name, list, { path: '/' })
    } 
    
    callback(list)
  },

  //cookie.save('current_cart_id', data.id, { path: '/' })
  
}

export default FavoriteAction
