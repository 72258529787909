import { useState } from 'react';

import FavoriteAction from './FavoriteAction';


function useFavoriteState() {

  const [list, setList] = useState([])

  const loadedHandler = (data) => {
    if(data)
      setList(data)
    else{
      setList([])
    }
  }


  const getList = () => {
    FavoriteAction.load(loadedHandler)
  } 

  const add = (id) => {
    FavoriteAction.add(id, loadedHandler)
  } 

  const remove = (id) => {
    FavoriteAction.remove(id, loadedHandler)
  } 


  const isFavorites = (movie_id) => {
    if(!list) return false
    const favo = list.find(item => item === movie_id)
    return favo >= 0 
  }

  


  return { favorites:list,  addFavorite:add, removeFavorite:remove, isFavorites:isFavorites, getFavorites:getList};
}

export default useFavoriteState