import React, { useState } from 'react'
import { useParams } from 'react-router-dom';
import { Button } from '../../stories/Button'
import { Movie } from '../../stories/Movie'

import {IoMdSkipBackward, IoMdSkipForward} from "react-icons/io"

import {GrCirclePlay ,GrPauseFill} from "react-icons/gr"
//import useFavoriteState from '../favorites/useFavoriteState';
import logo from "../../img/logo.png"

function MovieApp(props:PropsValue) {
  const {movie_id} = useParams()
 // const [is_favorite, setIsFavorite] = useState(false)
  const [is_playing, setIsPlaying] = useState(false)
  const [seek_time, setSeekTime] = useState(0)
  //const {favorites, addFavorite, removeFavorite, getFavorites, isFavorites} = useFavoriteState()

/**
  useEffect(() => {
    getFavorites()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(() => {
    setIsFavorite(isFavorites(movie_id))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[favorites])
 */
  const playerBtnHandler = (e) => {
    setIsPlaying(is_playing ? false : true)
  }


  const seek = (time) => {
    setSeekTime(time)
  }
/**
  const changeFavorite = () => {
    if(!isFavorites(movie_id)){
      addFavorite(movie_id)
    }else {
      removeFavorite(movie_id)
    }
  }

  const moveToFavorite = () => {
    window.location.href = "/favorite"
  }
 */
  const playHandler = () => {
    setIsPlaying(true)
  }

  const pauseHandler = () => {
    setIsPlaying(false)
  }

  const endedHandler = () => {
    setIsPlaying(false)
  }

  const playFromStart = () => {
    console.log("playFromStart")
    setSeekTime(10000000)
    setIsPlaying(true)

  }

  return(
    <div className="movie-wrap">
        <div className="movie-top">
          <div id="movie"> 
          <Movie movie_id={movie_id} seek_time={seek_time} is_playing={is_playing} resetSeekTime={() => seek(0)} playHandler={() => playHandler()} pauseHandler={() => pauseHandler()} endedHandler={() => endedHandler}/>
          </div>
          <div className='buttons-left'>
            <Button label={is_playing ? "一時停止" : "再生"} icon={is_playing ? <GrPauseFill /> : <GrCirclePlay />}  onClick={() => playerBtnHandler()} class_name={is_playing ? "green" : null}/>
            <Button label="戻る"  icon={<IoMdSkipBackward /> } primary onClick={() => seek(-10)}/>  
            <Button label="進む"  icon={<IoMdSkipForward /> } primary onClick={() => seek(10)}/>
            <Button label="最初から見る" ricon={<img src={logo} alt="TQ"/>} onClick={() => playFromStart()}/>
          </div>
        </div>
      
    </div>
     
  )
}

export default MovieApp
/** 
 *   <div className="movie-bottom">
           
          
        </div>
 */