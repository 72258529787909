import React, { useState, useEffect } from 'react';
import useMovieState from '../movie/useMovieState';
import Movie from "../movie/Movie"
import './thumnail.css';

interface ThumnailProps {
  /**
   * url for thumnal
   */
  movie_id: number;
  

  /**
   * click
   */
  //onClick: () => void;

}
  
/**
 * Primary UI component for user interaction
 */
export const Thumnail = ({
  movie_id,
  ...props
}: ThumnailProps) => {
  const [item, setItem] = useState< Movie | null | undefined >(null)
  const {movie_info, getMovieInfo} = useMovieState()

  useEffect(() => {
    getMovieInfo(movie_id);    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(() => {
    setItem(movie_info)
  },[movie_info])


  const clickHandler = () => {
    window.location.href = "/" + movie_id
  }

  return (

    <div>
      {!item ? null  :
        <div className="item" onClick={() => clickHandler()}>
          <div className="thumbnail"><img src={item.thumbnail_url} alt=""/></div>
          <p>{item.title}</p>
        </div>
      }
    </div>
  
  );
};
