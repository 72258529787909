import React, { ReactNode } from 'react';
import './button.css';

interface ButtonProps {
  /**
   * Is this the principal call to action on the page?
   */
  primary?: boolean;
  /**
   * What background color to use
   */
  backgroundColor?: string;
  /**
   * How large should the button be?
   */
  size?: 'small' | 'medium' | 'large';
  /**
   * Button contents
   */
  label: string;

   /**
   * Icon
   */
  icon?:ReactNode;

   /**
   * Icon
   */
   ricon?:ReactNode;


   /**
   * additional style
   */
   class_name?:string;

  
  /**
   * Optional click handler
   */
  onClick?: () => void;
}

/**
 * Primary UI component for user interaction
 */
export const Button = ({
  primary = false,
  size = 'medium',
  backgroundColor,
  label,
  icon,
  ricon,
  class_name,
  ...props
}: ButtonProps) => {
  const mode = primary ? 'storybook-button--primary' : 'storybook-button--secondary';
  
  return (
    <button
      type="button"
      className={['storybook-button', class_name, `storybook-button--${size}`, mode].join(' ')}
      style={{ backgroundColor }}
      {...props}
    > 
      <div className="labels">{icon ? <div className="icon">{icon}</div> : null}{label}{ricon ? <div className="ricon">{ricon}</div> : null}</div>
     
    </button>
  );
};
